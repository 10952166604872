import { FC } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { MedicalPrescriptionMetadata } from '../../services/medical/getMedicalPrescriptions'
import {
  Container,
  Flex,
  Grid,
  H2,
  H4,
  Image,
  List,
  Section,
  Text,
} from '../../ui'
import { usePlatoContext } from '../Plato/PlatoProvider'
import { usePrescriptionsContext } from './PrescriptionsProvider'

type RowProps = {
  isBold?: boolean
  col1: string
  col2: string
  col3: string
  col4: string
}

export type PrescriptionPrintoutProps = {
  prescription: MedicalPrescriptionMetadata
}
export const PrescriptionsPrintout: FC = () => {
  const { order } = usePlatoContext()
  const { id } = useParams()
  const { getPrescription } = usePrescriptionsContext()
  const { search } = useLocation()
  // eslint-disable-next-line no-undef
  const searchParams = new URLSearchParams(search)
  const printIndex = searchParams.get('index')

  if (!id || printIndex === null) {
    return <></>
  }
  const prescription = getPrescription(id, parseInt(printIndex, 10))

  if (!prescription) {
    return <></>
  }

  const ClinicDetails = () => {
    return (
      <List>
        <H4 className="bg-orange-500 p-2">Clinic's Details</H4>
        <Grid cols="grid-cols-12">
          <Text className="col-span-3">Name</Text>
          <Text isBold className="col-span-9">
            : ORA Clinic
          </Text>
          <Text className="col-span-3">Address</Text>
          <Text isBold className="col-span-9">
            : 47 Jalan Pemimpin #01-02 577200 Singapore
          </Text>
          <Text className="col-span-3">Email</Text>
          <Text isBold className="col-span-9">
            : medical.sg@ora.group
          </Text>
          <Text className="col-span-3">Phone No.</Text>
          <Text isBold className="col-span-9">
            : 65915393
          </Text>
        </Grid>
      </List>
    )
  }

  const PatientDetails = () => {
    const prescriptionDate = new Date(prescription.timestampCreated)
    return (
      <List>
        <H4 className="bg-orange-500 p-2">Patient's Details</H4>
        <Grid cols="grid-cols-12">
          <Text className="col-span-3">Name</Text>
          <Text isBold className="col-span-9">
            : {prescription.user.name}
          </Text>
          <Text className="col-span-3">ID No.</Text>
          <Text isBold className="col-span-9">
            : {prescription.user.nric}
          </Text>
          <Text className="col-span-3">Consultation Date</Text>
          <Text isBold className="col-span-9">
            :{' '}
            {`${prescriptionDate.toLocaleDateString()} ${prescriptionDate.toLocaleTimeString()}`}
          </Text>
          <Text className="col-span-3">Drug Allergies</Text>
          <Text isBold className="col-span-9">
            : NA
          </Text>
        </Grid>
      </List>
    )
  }

  const TableRow = ({ col1, col2, col3, col4, isBold }: RowProps) => {
    return (
      <>
        <Text className="p-1" isBold={isBold}>
          {col1}
        </Text>
        <Text className="col-span-3 p-1" isBold={isBold}>
          {col2}
        </Text>
        <Text className="col-span-5 p-1" isBold={isBold}>
          {col3}
        </Text>
        <Text className="col-span-3 p-1" isBold={isBold}>
          {col4}
        </Text>
      </>
    )
  }
  const OrdersDetails = () => {
    return (
      <List>
        <H4>Order ID: {order.orderId}</H4>
        <Grid cols="grid-cols-12 border-2 divide-x-2 divide-y-2" gap="gap-0">
          <TableRow
            isBold
            col1="#"
            col2="PRESCRIBED MEDICINES"
            col3="INSTRUCTIONS"
            col4="QUANTITY PRESCRIBED"
          />
          {prescription.products.map((product, index) => {
            return (
              <TableRow
                col1={String(index + 1)}
                col2={product.name}
                col3={product.instructions.remarks || ''}
                col4={product.instructions.quantityPrescribed || ''}
              />
            )
          })}
        </Grid>
        <Text className="text-sm">
          *For subscription products, you can refill this prescription for up to
          6 months
        </Text>
      </List>
    )
  }

  const SignatureLine = () => {
    return (
      <>
        <Grid cols="grid-cols-12">
          <Text className="col-span-3">Signature</Text>
          <Text isBold className="col-span-9">
            : <Image src={prescription?.doctor.signatureURL || ''} />
          </Text>
          <Text className="col-span-3">Doctor's Name and MCR</Text>
          <Text isBold className="col-span-9 uppercase">
            : {`${prescription.doctor.username} (${prescription.doctor.mcr})`}
          </Text>
        </Grid>
      </>
    )
  }
  return (
    <Section padding="py-20">
      <Container className="max-w-6xl">
        <List>
          <Flex alignItems="items-center" justifyContent="justify-center">
            <Image height={200} src="/ora-logo.png" width={200} />
          </Flex>
          <H2 className="mb-12 text-center">Prescription Slip</H2>
          <List gap="gap-12">
            <ClinicDetails />
            <PatientDetails />
            <OrdersDetails />
            <SignatureLine />
          </List>
        </List>
      </Container>
    </Section>
  )
}
